<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Etapas</h1>
    </div>
    <br />

    <DataGrid :config="dataGridConfig" :data="catalogos" :select="seleccionar" @actions="dataGridActions">
      <div class="row">
        <div class="col-sm-2"><button class="btn warning-btn" @click="obtener_catalogos">Recargar</button></div>
        <div class="col-sm-2"><button class="btn principal-btn" @click="nuevo_catalogo=true">Nuevo</button></div>
        <div class="col-sm-2"><button class="btn secondary-btn" @click="editar_catalogo">Editar</button></div>
        <div class="col-sm-2"><button class="btn danger-btn" @click="eliminar_catalogos">Eliminar</button></div>
      </div>
    </DataGrid>

    <Nuevo v-if="nuevo_catalogo" @close="cerrar_catalogo" @update="actualizar_catalogo" :editar_catalogo="catalogo" />
  </div>
</template>

<script type="text/javascript">
  import DataGrid from '@/components/DataGridV2'
  import Nuevo from './Catalogo/Nuevo'

  import api from '@/apps/metricas/api/catalogos'

  export default {
    components: {
      DataGrid, Nuevo
    }
    ,data: function() {
      return {
        dataGridConfig: {
          name: 'catalogos'
          ,cols: {
            nombre: 'Nombre'
            ,codigo: 'Código'
            ,estatus: 'Estatus'
            ,created_at: 'Fecha creación'
          }
          ,paginator: {
            pagina_actual: 1
            ,total_registros: 1
            ,registros_por_pagina: 20
          }
          ,mutators: {
            estatus: function(val) {
              return val == 1 ? 'Activo' : 'Inactivo';
            },
            created_at: function(val, row, vue) {
              return vue.$moment(val).format('YYYY-MM-DD HH:SS:DD');
            }
          }
        }
        ,options: {
          page: 1
          ,order_col: 'id'
          ,order_dir: 'desc'
          ,limit: 20
          ,filters: []
        }
        ,seleccionar: false
        ,seleccionadas: []
        ,catalogos: []
        ,nuevo_catalogo: false
        ,catalogo: null
      }
    }
    ,methods: {
      dataGridActions: function(tipo, data) {
        if (tipo == 'options') {
          this.options = data;
          this.obtener_catalogos();
        }else {
          this.seleccionadas = data;
          this.seleccionar = true;
        }
      }
      ,obtener_catalogos: async function() {
        try {
          let res = (await api.obtener_catalogos(this.options)).data;

          this.catalogos = res.data;
          this.dataGridConfig.paginator.pagina_actual = res.current_page;
          this.dataGridConfig.paginator.total_registros = res.total;
          this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
        }catch(e) {
          this.$log.info('err', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar_catalogo: function() {
        this.nuevo_catalogo = false;
        this.seleccionar = false;
        this.catalogo = null;
      }
      ,actualizar_catalogo: function() {
        this.cerrar_catalogo();
        this.obtener_catalogos();
      }
      ,editar_catalogo: async function() {
        try {
          if (this.seleccionadas.length == 0)
            return this.$helper.showMessage('Error','Debes seleccionar un catálogo a editar','error','alert');

          this.catalogo = (await api.obtener_catalogo_id(this.seleccionadas[0].id)).data;

          this.nuevo_catalogo = true;
        }catch(e) {
          this.$log.info('err', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,eliminar_catalogos: async function() {
        try {
            if (this.seleccionadas.length == 0)
              return this.$helper.showMessage('Error','Debes seleccionar al menos una catálogo a eliminar','error','alert');

            for(let i=0; i<this.seleccionadas.length; i++) {
              await api.eliminar_catalogo(this.seleccionadas[i].id);
            }

            this.seleccionar = false;
            this.obtener_catalogos();
        }catch(e) {
          this.$log.info('err', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>